import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CaptionText = styled.p`
  margin-top: 0;
`

const CenteredFlex = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  gap: 1em;
`

const FlexContent = styled.div`
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
`

const PortraitFlexContent = styled(FlexContent)`
  flex: 1 0 15em;
`

const LandscapeFlexContent = styled(FlexContent)`
  flex: 1 0 20em;
`

const CollectionView = ({ works }) => {
    // Create an image for each preview.
    const imagesInBoxes = works.map(({ image, slug, title }) => {
        // Compute reasonable dimensions that will make images look roughly the same size despite different proportions.
        const imgDetails = image.childImageSharp.gatsbyImageData
        const { width, height } = imgDetails
        const isPortrait = (width / height) < 1
        console.log(isPortrait)
        const FlexBox = isPortrait ? PortraitFlexContent : LandscapeFlexContent
        const gatsbyImage = <GatsbyImage image={ getImage(image) } alt={ title } style={{ width: "auto", margin: "1em" }} />
        const fullSlug = `/works/${ slug }`
        return (
            <FlexBox>
                <Link to={ fullSlug }>
                    { gatsbyImage }
                </Link>
                <FlexContent>
                    <FlexContent>
                        <CaptionText>{ title }</CaptionText>
                    </FlexContent>
                </FlexContent>
            </FlexBox>
        )
    }) 

    return (
        <CenteredFlex>
            { imagesInBoxes }
        </CenteredFlex>
    )
}

export default CollectionView
