/* From https://stackoverflow.com/a/196991/7191513 */
export const titlecase = (str) => {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const pluralize = (str) => str + 's'
